import { StarIcon } from "@heroicons/react/solid";
import React, { useRef } from "react";
import { SnapItem, SnapList } from "react-snaplist-carousel";
import data from "web/reviews-data.json";

export const AppReviewsContainer = () => {
  const snapList = useRef(null);
  return (
    <div className="pb-16 lg:pb-32">
      <SnapList
        direction="horizontal"
        className="pl-6 space-x-3 lg:space-x-8 overscroll-x-contain"
        ref={snapList}
      >
        {data.reviews.map(({ title, stars = 5, author, review }, i) => (
          <SnapItem
            snapAlign="center"
            className="flex flex-col w-11/12 p-4 space-y-4 rounded md:w-1/2 lg:w-1/3 bg-secondary"
            key={i}
          >
            <div className="flex justify-between">
              <div className="space-y-1">
                <h4 className="font-extrabold tracking-wide text-foreground font-display">
                  {title}
                </h4>
                <div className="flex space-x-2">
                  {[...new Array(stars)].map((_, i) => (
                    <StarIcon key={i} className="w-5 h-5 text-primary" />
                  ))}
                </div>
              </div>
              <div>
                <p className="text-foreground">{author}</p>
              </div>
            </div>
            <p
              style={{ color: "#BCBCBC" }}
              className="block text-opacity-50 review-details"
              dangerouslySetInnerHTML={{
                __html: review,
              }}
            />
          </SnapItem>
        ))}
      </SnapList>
    </div>
  );
};

import Img from "next/image";
import { Action } from "web/components/action";

export const TrainingContainer = () => {
  return (
    <>
      <div className="relative z-20 lg:z-10 lg:flex mb-20 items-center justify-center container max-w-6xl mx-auto">
        <Img
          className="w-full max-w-md h-auto -mt-10 px-4 mx-auto"
          width={512 * 1.5}
          height={990 * 1.5}
          alt="Corey"
          src="https://ladderteams.imgix.net/website/phone/corey_v0.png?w=1200"
        />
        <div className="relative flex flex-col-reverse items-center justify-center h-full px-2 mx-auto lg:mx-12 lg:max-w-sm lg:flex-row">
          <div className="w-full mb-24 space-y-8">
            <h2 className="text-5xl text-center text-foreground uppercase lg:text-6xl lg:text-left heading">
              <span className="text-lg lg:text-4xl">Learn from</span> <br /> the
              best
            </h2>
            <p className="px-6 text-lg text-center mx-auto lg:mx-0 text-foreground opacity-60 lg:text-left lg:pr-20 lg:pl-0 max-w-md">
              Get answers to your questions and expert coaching through it all.
            </p>
            <div className="flex mt-10 center lg:block lg:mt-110">
              <Action
                at="home.hero.cta"
                className="block px-10 py-4 mx-auto text-black rounded-full cta bg-primary lg:mx-0 text-center"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-20 lg:z-10 mb-20 lg:flex lg:flex-row-reverse items-center justify-center container max-w-6xl mx-auto">
        <Img
          className="w-full max-w-md h-auto mx-auto px-4"
          width={512 * 1.5}
          height={990 * 1.5}
          alt="Records"
          src="https://ladderteams.imgix.net/website/phone/records_v1.png?w=1200"
        />
        <div className="relative flex flex-col-reverse items-center justify-center h-full px-2 mx-auto lg:mx-12 lg:max-w-sm lg:flex-row">
          <div className="w-full mb-24 space-y-8">
            <h2 className="text-5xl text-center text-foreground uppercase lg:text-6xl lg:text-left heading">
              <span className="text-lg lg:text-4xl">Track Your</span> <br />{" "}
              Progress
            </h2>
            <p className="px-6 text-lg text-center mx-auto lg:mx-0 text-foreground opacity-60 lg:text-left lg:pr-20 lg:pl-0 max-w-md">
              Integrated rep & weight tracking to visualize your progress, get
              PR alerts, and manage training notes in one place. 
            </p>
            <div className="flex mt-10 center lg:block lg:mt-110">
              <Action
                at="home.hero.cta"
                className="block px-10 py-4 mx-auto text-black rounded-full cta bg-primary lg:mx-0 text-center"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

"use client";
import { AnimatePresence, motion, useScroll, useTransform } from "motion/react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import React from "react";
import useInView from "react-cool-inview";
import AnimatedShinyText from "ui/components/shiny-text";
import { useCountdown } from "ui/hooks/misc";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { Action } from "web/components/action";
import { PromoCodeShimmerText } from "web/components/promo-code/shimmer-text";
import { ReferralAction } from "web/components/ReferralAction";
import { accessVariant } from "web/components/text-node";
import { useApp } from "web/state-containers/app";
import { useExperiment } from "web/state-containers/experiment";
import { fancyTimeFormat } from "../../workout/helpers";

const MotionImage = motion.create(Image);

const Text = dynamic(
  () =>
    import("web/components/text-node").then((mod) => ({
      default: mod.Text,
    })),
  { ssr: false }
);
const PhoneImage = dynamic(
  () =>
    import("./phone-image").then((mod) => ({
      default: mod.PhoneImage,
    })),
  { ssr: false }
);

const Countdown = React.memo(() => {
  const { countDown } = useCountdown(60 * 30);

  return (
    <span className="font-display center text-3xl" key={countDown}>
      {fancyTimeFormat(countDown)}
    </span>
  );
});

export const HeroNext = ({
  scrollYProgessMultiplier = 1500,
  heroTitleAt = "home.hero.title",
}) => {
  const { setIsHeroCTAInView } = useApp();
  const searchParams = useSearchParams();
  const { variant } = useExperiment();
  const { scrollYProgress } = useScroll();
  const contentY = useTransform(
    scrollYProgress,
    (value) => (value / -1) * scrollYProgessMultiplier
  );

  const { observe } = useInView({
    threshold: 0, // Default is 0
    onEnter: () => {
      setIsHeroCTAInView(true);
    },
    onLeave: () => {
      setIsHeroCTAInView(false);
    },
    // More useful options...
  });

  const isMobile = useIsMobile();
  const marginBottom = isMobile ? 150 : 300;
  const delay = 0.25;

  const screenshot = searchParams ? searchParams.get("screenshot") : null;
  const promoCode = searchParams ? searchParams.get("promoCode") : null;

  return (
    <section className="relative w-full h-[calc(100vh+100px)]">
      <div className="absolute z-40 inset-x-0 top-0 h-[24rem] bg-gradient-to-b from-background to-transparent" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: delay * 5 }}
        style={{
          height: `calc(100vh + ${marginBottom}px)`,
          backgroundImage: `url(https://ladderteams.imgix.net/website/hero/${accessVariant("home.hero.bg", "ab-test-home-hero_12_24")})`,
        }}
        className={cn(
          "absolute inset-0 z-30 w-full h-screen bg-center bg-no-repeat bg-cover"
        )}
      ></motion.div>
      <motion.div className="relative z-40 flex flex-col items-center justify-start h-full max-w-5xl pt-4 mx-auto lg:pt-6 xl:pt-8">
        <div className={cn("relative max-w-full lg:w-52 w-36")}>
          <MotionImage
            width={208}
            height={98}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
            src="/assets/apple-stars-and-reviews.svg"
            className="w-full mb-4"
            alt="Apple ratings and reviews"
          />
        </div>
        <motion.div
          style={{ y: contentY }}
          className="relative z-10 flex flex-col items-center justify-start mx-auto transform -translate-y-8"
        >
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 2 }}
            className={cn(
              "mb-4 px-4 leading-8 text-2xl min-[388px]:text-3xl md:text-5xl lg:text-6xl text-center text-foreground uppercase md:leading-tight md:mb-8 font-title whitespace-pre"
            )}
          >
            <Text at={"home.hero.title.ab-test-home-hero_12_24"} />
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 3 }}
            style={{ color: "#C1C1C1" }}
            className="max-w-3xl px-6 mx-auto mb-8 text-center text-foreground md:mb-8 md:text-xl lg:px-20"
          >
            <Text at="home.hero.description" />
          </motion.p>
          <motion.div
            ref={observe}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 4 }}
            className="px-8 md:px-4 w-full max-w-md mx-auto"
          >
            {variant === "referral" ? (
              <ReferralAction
                at="home.hero.cta"
                className="px-10 py-4 w-full text-center text-primary-foreground rounded-full cta bg-primary"
              />
            ) : (
              <Action
                at="home.hero.cta"
                className="px-10 py-4 w-full text-center text-primary-foreground rounded-full cta bg-primary"
              />
            )}
          </motion.div>
          <AnimatePresence>
            {!!promoCode ? (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: delay * 5, weight: 3 }}
                className="px-4 mt-4 mb-4"
              >
                <PromoCodeShimmerText promoCode={promoCode} variant={variant} />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: delay * 4 }}
                className="px-4 text-foreground/80 mt-6 mb-1"
              >
                <AnimatedShinyText
                  className="max-w-sm mx-auto text-center"
                  shimmerWidth={209}
                >
                  <span className="text-foreground/70">
                    <span
                      style={{
                        backgroundImage:
                          "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
                        backgroundClip: "text",
                      }}
                      className="font-medium block text-white/40 bg-clip-text"
                    >
                      No Credit Card To Start • 100% Risk-Free
                    </span>
                  </span>
                </AnimatedShinyText>
              </motion.div>
            )}
          </AnimatePresence>
          <PhoneImage
            variant={screenshot ? screenshot : variant}
            delay={delay}
          />
        </motion.div>
      </motion.div>
    </section>
  );
};

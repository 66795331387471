import Image from "next/image";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { Action } from "web/components/action";
import { Text } from "web/components/text-node";

export const PaymentTeaser = () => {
  const isMobile = useIsMobile();
  return (
    <div className="relative z-10 w-full pt-32 pb-32 lg:pb-56 bg-basic">
      <div className="container flex flex-col items-center justify-center mx-auto">
        <div className="w-full h-full px-4 mb-16 bg-center bg-no-repeat bg-contain lg:mb-0">
          <h2 className="mb-12 text-4xl text-center text-black uppercase lg:mb-0 lg:text-left lg:text-6xl heading">
            <Text at="home.payment.title" />
          </h2>
          <Image
            width={1000}
            height={600}
            className="w-full h-full px-2 transform bg-center bg-no-repeat object-contain md:scale-110 lg:scale-100 lg:-mt-8"
            src={`https://ladderteams.imgix.net/website/cards/pricing-cards-${
              isMobile ? "mobile_v0" : "desktop"
            }.png?w=1200`}
            alt="ladder app"
          />
        </div>
        <Action
          at="home.payment.cta"
          className="block px-10 py-4 mx-auto text-foreground bg-background rounded-full cta lg:mx-0"
        />
      </div>
    </div>
  );
};

import { cn } from "ui/lib/utils";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { AnimatePresence, motion } from "motion/react";
import { useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import faqData from "ui/data/faq-data.json";
import { useMeasure } from "ui/lib/use-measure";

export function AnimateHeight({
  duration,
  ease,
  variants,
  isVisible,
  children,
  ...other
}) {
  const ref = useRef(null);
  const bounds = useMeasure(ref);

  return (
    <motion.div
      className="overflow-hidden"
      initial={isVisible ? "open" : "collapsed"}
      animate={isVisible ? "open" : "collapsed"}
      inherit={false}
      variants={variants}
      transition={{
        ease,
        duration:
          typeof duration === "number"
            ? duration
            : getAutoHeightDuration(bounds.height) / 1000,
      }}
      {...other}
    >
      {typeof children === "function" ? (
        children(ref)
      ) : (
        <div ref={ref}>{children}</div>
      )}
    </motion.div>
  );
}

/**
 * Get the duration of the animation depending upon
 * the height provided.
 * @param {number} height of container
 */

export function getAutoHeightDuration(height) {
  if (!height) return 0;
  const constant = height / 36;
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

export function renderItem(item, nested = 0, key) {
  if (typeof item === "string") {
    return (
      <ReactMarkdown
        key={key}
        rehypePlugins={[rehypeRaw]}
        className={cn("markdown accordion-item", { nested: nested > 1 })}
      >
        {item}
      </ReactMarkdown>
    );
  }
  if (Array.isArray(item)) {
    return item.map((n, i) => renderItem(n, nested + 1, i));
  }
}

export const Accordion = ({ item, i, expanded, setExpanded }) => {
  const isOpen = i === expanded;

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
    },
    collapsed: { opacity: 0, height: 0 },
  };

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <div>
      <motion.header
        initial={false}
        className={cn(
          "px-4 lg:px-12 py-6 leading-normal lg:py-8 cursor-pointer lg:text-lg flex justify-between font-bold items-center uppercase bg-basic-600 text-black text-opacity-90",
          {
            rounded: !isOpen,
            "rounded-t-lg": isOpen,
          }
        )}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <span>{item.title}</span>
        <motion.div animate={{ rotate: isOpen ? 180 : 0 }}>
          <ChevronDownIcon
            style={{ minWidth: "1.5rem" }}
            className="w-6 h-6 text-sm min-w-6 lg:w-10 lg:h-10 text-charcoal"
          />
        </motion.div>
      </motion.header>
      <AnimateHeight variants={variants} isVisible={isOpen}>
        <motion.section className="px-4 pb-6 space-y-4 text-lg text-black rounded-b-lg faq-details lg:px-12 lg:pb-8 bg-basic-600">
          {renderItem(item.details)}
        </motion.section>
      </AnimateHeight>
    </div>
  );
};

export const FaqContainer = ({ filter = [] }) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState();
  const data = useMemo(() => {
    return faqData.filter(({ id }) => !filter.includes(id));
  }, [filter]);
  const [Qs, setQs] = useState(data.slice(0, 5));

  return (
    <div className="relative w-full pb-32 lg:pb-56 bg-basic">
      <div className="container px-4 mx-auto">
        <h1 className="mb-12 text-3xl lg:text-5xl heading text-black">
          Still have questions?
        </h1>
        <motion.div className="space-y-4">
          <AnimatePresence initial={false} mode="wait">
            {Qs.map((item, i) => (
              <Accordion
                key={i}
                i={i}
                item={item}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            ))}
          </AnimatePresence>
          <div className="center">
            {Qs.length !== data.length ? (
              <button
                className="px-6 py-2 mx-auto text-lg text-center cta text-black"
                aria-label="show all FAQs"
                onClick={() => setQs(data)}
              >
                Show All
              </button>
            ) : null}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

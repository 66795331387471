export const WithPhone = ({ radius, stroke, progress, ...props }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2} {...props}>
      <defs>
        <filter
          id="f1"
          x="-50%"
          y="-40%"
          width="150%"
          height="150%"
          filterUnits="userSpaceOnUse"
        >
          <feColorMatrix result="offsetblur" in="offOut" type="matrix" />
          <feGaussianBlur in="SourceAlpha" stdDeviation="7" />
          <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
          <feOffset
            in="blur"
            dx="3"
            dy="3"
            result="offsetBlur"
            stdDeviation="1"
          />
          <feFlood floodColor="#E6FF00" floodOpacity="2" result="offsetColor" />
          <feComposite
            in="offsetColor"
            in2="offsetBlur"
            operator="in"
            result="offsetBlur"
          />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle
        stroke="#E6FF00"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + " " + circumference}
        // style={{ strokeDashoffset, filter: "url(#f1)" }}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

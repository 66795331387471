"use client";

import Image from "next/image";
import useInView from "react-cool-inview";
import { useWindowSize } from "react-use";
import { useBreakpoint } from "ui/lib/use-breakpoint";
import { Action } from "web/components/action";
import { WithPhone } from "web/components/ring/w-phone";
import { Text } from "web/components/text-node";
import { DaysComponent } from "./days";

export const EachDaySectionDesktop = ({ progress }) => {
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    threshold: 0, // Default is 0
    onLeave: ({ scrollDixrection, entry, observe, unobserve }) => {
      // Triggered when the target leaves the viewport
      if (scrollDirection.vertical === "up") {
        observe;
      }
    },
    // More useful options...
  });
  const breakpoint = useBreakpoint();
  const { width: windowWidth } = useWindowSize();

  return (
    <div>
      <div
        // style={{ height: `calc(100vh - 250px)` }}
        className="flex flex-col items-center justify-center w-full h-screen px-12 mx-auto max-w-screen-2xl lg:flex-row"
      >
        <div className="w-full space-y-8">
          <DaysComponent progress={progress} />
          <h2 className="text-5xl leading-tight text-left text-foreground heading lg:pr-28">
            <Text at="home.eachDay.title" />
          </h2>
          <p className="text-xl text-left text-foreground/60 lg:max-w-sm">
            <Text at="home.eachDay.description" />
          </p>
          <Action
            at="home.hero.cta"
            event={{ action: "find_my_plan_cta_clicked" }}
            className="px-10 py-4 text-primary-foreground rounded-full cta bg-primary"
          />
        </div>

        <div ref={observe} className="relative w-full">
          <div className="max-w-xl mx-auto">
            <div className="absolute inset-0 flex items-end justify-center w-full h-full">
              <WithPhone
                key={breakpoint}
                radius={breakpoint === "sm" ? windowWidth / 2 - 20 : 250}
                stroke={breakpoint === "sm" ? 14 : 18}
                progress={progress * 100}
                className="relative mx-auto scale-110 -rotate-45 top-4 md:top-6"
              />
            </div>
            <Image
              style={{ padding: "0 55px" }}
              width={500}
              height={500}
              className="relative z-10 mx-auto"
              src="https://ladderteams.imgix.net/website/phone/in-ring-lg.png"
              alt="ladder app on an iPhone"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { Controller, Scene } from "react-scrollmagic";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { EachDaySectionDesktop } from "web/components/home/every-day-section/desktop";
import { EachDaySectionMobile } from "web/components/home/every-day-section/mobile";

export const EachDaySection = () => {
  const isMobile = useIsMobile();
  return (
    <div>
      <div className={"lg:hidden pb-16"}>
        <EachDaySectionMobile
          {...{
            progress: 50,
          }}
        />
      </div>
      <div className="hidden lg:block">
        <Controller>
          <Scene
            triggerHook={isMobile ? 0.25 : 0}
            duration={300}
            enabled={!isMobile}
            pin={!isMobile}
          >
            {(progress) => {
              return (
                <section>
                  <div
                    className={cn({
                      hidden: isMobile,
                    })}
                  >
                    <EachDaySectionDesktop
                      {...{
                        progress,
                      }}
                    />
                  </div>
                </section>
              );
            }}
          </Scene>
        </Controller>
      </div>
    </div>
  );
};

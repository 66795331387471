"use client";
import {
  AnimatePresence,
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from "motion/react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import React from "react";
import useInView from "react-cool-inview";
import { useVideo } from "react-use";
import { PhoneOutline } from "ui/components/phone-outline";
import { useCountdown } from "ui/hooks/misc";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { Action } from "web/components/action";
import { PromoCodeShimmerText } from "web/components/promo-code/shimmer-text";
import { ReferralAction } from "web/components/ReferralAction";
import { accessVariant } from "web/components/text-node";
import { VideoPlayer } from "web/components/workout/player/video-player";
import { useApp } from "web/state-containers/app";
import { useExperiment } from "web/state-containers/experiment";
import { fancyTimeFormat } from "../../workout/helpers";

const MotionImage = motion.create(Image);

const Text = dynamic(
  () =>
    import("web/components/text-node").then((mod) => ({
      default: mod.Text,
    })),
  { ssr: false }
);
const PhoneImage = dynamic(
  () =>
    import("./phone-image").then((mod) => ({
      default: mod.PhoneImage,
    })),
  { ssr: false }
);

const Countdown = React.memo(() => {
  const { countDown } = useCountdown(60 * 30);

  return (
    <span className="font-display center text-3xl" key={countDown}>
      {fancyTimeFormat(countDown)}
    </span>
  );
});

const AnimatedPhone = ({ delay }) => {
  const { scrollY } = useScroll();
  const rotateX = useTransform(scrollY, [0, 250], [14, 0]);
  const translateY = useTransform(scrollY, [0, 250], [-24, 0]);
  const scale = useTransform(scrollY, [0, 250], [0.95, 1]);
  const isMobile = useIsMobile();

  const { observe, inView } = useInView({
    threshold: 0, // Default is 0
    // More useful options...
  });

  const [video, videoState, videoControls, videoRef] = useVideo(
    <VideoPlayer
      className="absolute rounded-[40px] inset-0 object-cover w-full h-full max-w-full mx-auto sm:max-w-lg lg:max-w-lg 2xl:max-w-xl 4xl:max-w-2xl"
      controls={false}
      autoPlay={true}
      playsInline={true}
      muted={true}
      preload="auto"
      width={"100%"}
      url={
        "https://ladder-bootcamp-media-prod.s3.amazonaws.com/movement-videos/Lauren_Meghan/110_double_arm_continous_swing.mp4"
      }
    />
  );

  const transform = useMotionTemplate`perspective(1200px) translateX(0px) translateY(${translateY}px) scale(${scale}) rotate(0deg) rotateX(${rotateX}deg) rotateY(0deg) translateZ(0px)`;
  // perspective(1200px) translateX(0px) translateY(-16.6304px) scale(0.988768) rotate(0deg) rotateX(1.68478deg) rotateY(0deg) translateZ(0px)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: delay * 5 }}
      style={{
        transform,
      }}
      src="https://ladderteams.imgix.net/website/hero/phone.png"
      className="w-full flex center max-w-[420px] xl:max-w-xl"
    >
      <PhoneOutline
        showBrowser={false}
        height={isMobile ? 770 : 900}
        width={isMobile ? "100%" : 480}
      >
        <div className="absolute w-full top-0 z-10 flex center px-8 text-foreground">
          <div
            style={{
              width: 220,
              height: 42,
              borderRadius: "0 0 28px 28px",
            }}
            className="mx-auto center notch bg-background"
          >
            <div
              style={{
                boxShadow:
                  "0 1px 2px rgb(255 255 255 / 12%), inset 0 -0.5px 0 rgb(255 255 255 / 15%), inset 0 0 1px rgb(0 0 0), inset 0 2px 2px rgb(0 0 0 / 45%)",
              }}
              className="w-12 h-1 py-[.18rem] mx-auto my-1 bg-secondary rounded-full"
            />
          </div>
        </div>
        <div className="w-full h-full rounded-[40px] overflow-hidden">
          {video}
          <div className="absolute rounded-br-3xl border-b border-neutral-700 top-0 w-full pt-14 pb-3 px-4 flex items-center justify-between text-foreground bg-secondary/80 backdrop-blur-md">
            <div className="w-12 h-12 relative center">
              <span className="animate-ping absolute inline-flex h-2/3 w-2/3 duration-1000 rounded-full bg-primary opacity-75"></span>
              <img
                className="rounded-full relative border-primary border"
                src="https://ladderteams.imgix.net/workout-coach-avatars/Body%20%26%20Bell.png?w=100"
                alt="coach avatar"
              />
            </div>
            <Countdown />
            <div className="w-8 h-8 center">
              <img src="/assets/apple-music-icon.png" alt="apple music icon" />
            </div>
          </div>
          {!isMobile ? (
            <motion.div
              animate={{ y: !inView ? 300 : 0 }}
              transition={{ type: "spring", bounce: 0.15, delay: 1 }}
              className="absolute rounded-tr-3xl border-t border-neutral-700 bottom-0 w-full pb-5 pt-3 px-4 flex items-center justify-end text-black bg-primary"
            >
              <div className="flex flex-col text-right">
                <span className="text-base font-display">UP NEXT</span>
                <span className="text-sm font-medium">KB Goblet Squat</span>
              </div>
            </motion.div>
          ) : null}
          <div
            ref={observe}
            className="h-2 w-full absolute bottom-0 z-20"
          ></div>
        </div>
      </PhoneOutline>
    </motion.div>
  );
};

export const Hero = ({
  scrollYProgessMultiplier = 1500,
  heroTitleAt = "home.hero.title",
}) => {
  const { setIsHeroCTAInView } = useApp();
  const searchParams = useSearchParams();
  const { variant } = useExperiment();
  const { scrollYProgress } = useScroll();
  const contentY = useTransform(
    scrollYProgress,
    (value) => (value / -1) * scrollYProgessMultiplier
  );

  const { observe } = useInView({
    threshold: 0, // Default is 0
    onEnter: () => {
      setIsHeroCTAInView(true);
    },
    onLeave: () => {
      setIsHeroCTAInView(false);
    },
    // More useful options...
  });

  const isMobile = useIsMobile();
  const marginBottom = isMobile ? 150 : 300;
  const delay = 0.25;

  const screenshot = searchParams ? searchParams.get("screenshot") : null;
  const promoCode = searchParams ? searchParams.get("promoCode") : null;

  return (
    <section className="relative w-full h-[calc(100vh+100px)]">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: delay * 5 }}
        style={{
          height: `calc(100vh + ${marginBottom}px)`,
          backgroundImage: `url(https://ladderteams.imgix.net/website/hero/${
            isMobile ? "mobile/" : ""
          }${accessVariant("home.hero.bg", variant)})`,
        }}
        className={cn(
          "absolute mt-32 lg:mt-12 inset-0 z-30 w-full h-screen bg-center bg-no-repeat bg-cover"
        )}
      ></motion.div>
      <motion.div className="relative z-40 flex flex-col items-center justify-start h-full max-w-5xl pt-4 mx-auto lg:pt-6 xl:pt-8">
        <div className={cn("relative max-w-full lg:w-52 w-36")}>
          <MotionImage
            width={208}
            height={98}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
            src="/assets/apple-stars-and-reviews.svg"
            className="w-full mb-4"
            alt="Apple ratings and reviews"
          />
        </div>
        <motion.div
          style={{ y: contentY }}
          className="relative z-10 flex flex-col items-center justify-start mx-auto transform -translate-y-8"
        >
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 2 }}
            className={cn(
              "mb-4 px-4 leading-8 text-2xl text-center text-foreground uppercase lg:leading-tight md:mb-8 lg:text-5xl",
              {
                // "font-title": ["BlackFridayAnnual"].includes(variant),
                "font-title whitespace-pre": [
                  "referral",
                  "BlackFridayAnnual",
                  "BlackFridayMonthly",
                  "BlackFridayMonthly12",
                ].includes(variant),
                heading: ![
                  "referral",
                  "BlackFridayAnnual",
                  "BlackFridayMonthly",
                  "BlackFridayMonthly12",
                ].includes(variant),
              }
            )}
          >
            <Text at={heroTitleAt} />
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 3 }}
            style={{ color: "#C1C1C1" }}
            className="max-w-3xl px-6 mx-auto mb-8 text-center text-foreground md:mb-8 md:text-xl lg:px-20"
          >
            <Text at="home.hero.description" />
          </motion.p>
          <motion.div
            ref={observe}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 4 }}
            className="px-8 md:px-4 w-full max-w-md mx-auto"
          >
            {variant === "referral" ? (
              <ReferralAction
                at="home.hero.cta"
                className="px-10 py-4 w-full text-center text-primary-foreground rounded-full cta bg-primary"
              />
            ) : (
              <Action
                at="home.hero.cta"
                className="px-10 py-4 w-full text-center text-primary-foreground rounded-full cta bg-primary"
              />
            )}
          </motion.div>
          <AnimatePresence>
            {!!promoCode ? (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: delay * 5, weight: 3 }}
                className="px-4 mt-4 mb-4"
              >
                <PromoCodeShimmerText promoCode={promoCode} variant={variant} />
              </motion.div>
            ) : (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: delay * 4 }}
                className="px-4 text-foreground/80 mt-4 mb-2"
              >
                No credit card required
              </motion.p>
            )}
          </AnimatePresence>
          <PhoneImage
            variant={screenshot ? screenshot : variant}
            delay={delay}
          />
        </motion.div>
      </motion.div>
    </section>
  );
};
